import { UserProfile } from "@lobby/core/src/components";
import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";
import { redirectIfAuthenticatedUserRequired } from "app/lib";
import { loadLobbyConfig } from "app/lib/loaders";
import { TakeBonusModal } from "modals/take-bonus/take-bonus-modal";

export const Route = createFileRoute("/_auth")({
  beforeLoad: () => redirectIfAuthenticatedUserRequired(),
  loader: () => loadLobbyConfig(),
  component: () => (
    <>
      <UserProfile />
      <TakeBonusModal />

      <Outlet />
    </>
  ),
});
