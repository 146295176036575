import { useNavigate } from "@tanstack/react-router";

import { Button, Icon } from "@lobby/core/src/shared";

export function ResetProviderFilterButton() {
  const navigate = useNavigate();
  return (
    <Button
      fit
      variant="secondary"
      onClick={() => {
        navigate({
          replace: true,
          // @ts-ignore
          search: (prev) => ({
            ...prev,
            pid: undefined,
          }),
        });
      }}
    >
      <Icon className="text-16" name="closeAlt" />
    </Button>
  );
}
