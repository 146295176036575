import { JackpotContextProvider, LocaleProvider, queryClient } from "@lobby/core/src/app";
import {
  LobbySettingsContextProvider,
  PlayerSettingsContextProvider,
} from "@lobby/core/src/entities";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { localesMap } from "shared/lib";
import { Logo, Spinner } from "shared/ui";
import { router } from "./config";

import "./index.css";

export function App() {
  // Set default EN locale if it's not set, or if locale in localStorage is not in localesMap,
  // due to the fact that it was removed from the app.
  const locale = localStorage.getItem("locale");
  if (!localesMap[locale as keyof typeof localesMap]) {
    localStorage.setItem("locale", "en");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <LobbySettingsContextProvider Logo={Logo} Spinner={Spinner}>
        <PlayerSettingsContextProvider>
          <JackpotContextProvider>
            <LocaleProvider>
              <RouterProvider router={router} />
            </LocaleProvider>
          </JackpotContextProvider>
        </PlayerSettingsContextProvider>
      </LobbySettingsContextProvider>
    </QueryClientProvider>
  );
}

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
