import { Player, useJackpotsContext, usePlayerMoneyFormatter } from "@lobby/core/src/entities";
import { clsx } from "clsx";
import { JackpotTicker } from "entities/jackpot";

import "./styles.css";

export function JackpotTickers({ className, compact }: { className?: string; compact?: boolean }) {
  const { slots } = useJackpotsContext();
  const player = Player.usePlayer();
  const formatMoney = usePlayerMoneyFormatter();

  if (!player?.data) {
    return null;
  }

  return (
    slots && (
      <div className={clsx(className, "jackpot-tickers", { "jackpot-tickers__compact": compact })}>
        {slots.map((slot) => (
          <JackpotTicker key={slot.slot} type={slot.name}>
            {formatMoney(slot.value)}
          </JackpotTicker>
        ))}
      </div>
    )
  );
}
