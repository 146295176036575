import { Button } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";
import { memo, useEffect, useState } from "react";
import type { TBanner } from "../lib/types";

interface IBannersListProps {
  banners: TBanner[];
}

export const BannersListDesktop = memo(function BannersListDesktop({ banners }: IBannersListProps) {
  const [activeBanners, setActiveBanners] = useState(banners);
  const { $t } = useTranslate();

  function flipBanners() {
    setActiveBanners((prev) => {
      const [head, ...tail] = prev;
      return [...tail, head];
    });
  }

  useEffect(() => {
    setActiveBanners(banners);
  }, [banners]);

  return (
    <>
      {activeBanners.length > 3 && (
        <div
          className="bg-keppel/70 dark:bg-java/70 absolute top-[-0.375rem] w-full h-[0.125rem] animate-[growing_10s_linear_infinite] group-hover:[animation-play-state:paused]"
          onAnimationIteration={flipBanners}
        />
      )}
      {activeBanners.map(({ id, image, title, subtitle, button }, idx) => {
        const splitTitle = title.split("\n");
        const splitSubtitle = subtitle.split("\n");

        return (
          <div
            className={clsx(
              idx > 2 && "hidden",
              "p-[1.25em] widescreen:text-[1rem] text-[0.83333vw] bg-athens-gray rounded-rounded relative h-full dark:bg-outer-space",
            )}
            key={id}
          >
            <img
              className="rounded-inherit absolute top-0 left-0 h-full w-full object-cover"
              src={image}
              alt={skipIntl("promo-img")}
            />
            <div className="relative h-full select-none">
              {(title || subtitle) && (
                <div
                  dir="ltr"
                  className="p-[1em] rtl:text-end bg-ebony-clay/80 rounded-rounded text-white whitespace-break-spaces overflow-hidden absolute top-0 max-h-full max-w-full"
                >
                  {splitTitle.map((substr, idx) => (
                    <div key={idx} className="text-[1.5em] [line-height:1.5] font-bold">
                      {substr}
                    </div>
                  ))}
                  {splitSubtitle.map((substr, idx) => (
                    <div key={idx} className="text-[1.25em] [line-height:1.5]">
                      {substr}
                    </div>
                  ))}
                </div>
              )}
              {button && (
                <div className="absolute bottom-0 ltr:left-0 rtl:right-0">
                  <Button variant="primary" onClick={button.action}>
                    {button.textKey === "playNow"
                      ? $t({ defaultMessage: "play now" })
                      : $t({ defaultMessage: "open" })}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
});
