import { Button, Icon, LanguageSelector, ThemeSwitch } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";

export function ErrorComponent({ error }: { error: any }) {
  const { $t } = useTranslate();

  return (
    <div className="fixed inset-0 flex-center mobile-only:flex-col px-5 lg:gap-10">
      <div className="text-[9rem] flex-center text-keppel lg:text-[16rem] dark:text-java">
        <Icon name="gamepadSolid" />
      </div>
      <div className="flex max-w-[30rem] flex-col items-center lg:items-start">
        {error?.code ? (
          <h1 className="font-bold text-6xl">{error.code}</h1>
        ) : (
          <h1 className="font-bold text-4xl">{$t({ defaultMessage: "Unknown error" })}</h1>
        )}
        <p className="my-5 mobile-only:text-center text-sm lg:text-lg">
          {$t({
            defaultMessage:
              "Sorry! Games are temporarily unavailable! Technical work is underway! We will fix everything soon.",
          })}
        </p>
        <Button variant="primary" onClick={() => window.location.reload()}>
          {$t({ defaultMessage: "Reload page" })}
        </Button>
        <hr className="my-5 w-full text-shark dark:text-silver" />
        <div className="flex w-full items-center justify-between">
          <LanguageSelector orientation="top" />
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
}
