import { MOBILE_BREAKPOINT, clientCategories, clientCategoryIdMap } from "../const";

export function checkMobile() {
  return window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`).matches;
}

export function isAllGamesCategory(id: string | number | undefined) {
  return String(id) === clientCategories.allGames.id;
}

export function isFavouritesCategory(id: string | number | undefined): id is string {
  return id === clientCategories.favourites.id;
}

export function isRecommendedCategory(id: string | number | undefined): id is string {
  return id === clientCategories.recommended.id;
}

export function isSearchResultsCategory(id: string | number | undefined) {
  return String(id) === clientCategories.searchResults.id;
}

export function isClientCategory(id: string | number | undefined) {
  return (
    isAllGamesCategory(id) ||
    isSearchResultsCategory(id) ||
    isFavouritesCategory(id) ||
    isRecommendedCategory(id)
  );
}

export function getClientCategoryName(id: string | number) {
  return clientCategoryIdMap[String(id)];
}

const gameCategoriesMapValues = Object.values(clientCategories);
export function getCategoryIconName(id: string): any {
  return gameCategoriesMapValues.find((c) => id === c.id)?.icon ?? "recommended";
}

export function getProviderImageSrc(theme: string, stringId: string | number) {
  return `/media/game-provider/${theme}/${stringId}.svg`;
}

export function getCategoryImageSrc(theme: string, stringId: string | number) {
  return `/media/game-category/${theme}/${stringId}.svg`;
}

export function getDocumentLocale() {
  return document.documentElement.lang || "en";
}

export function getRootFontSize(): number {
  return Number.parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function remToPx(rem: string | number): number {
  return Number.parseFloat(rem as string) * getRootFontSize();
}

export function moveItemToHead<T extends { id: number }>(id: number, list: T[]): T[] {
  if (!id) return list;

  let keyItem: T;

  return list.reduce<T[]>((acc, item, idx, arr) => {
    if (item.id === id) {
      keyItem = item;
    }

    if (idx === arr.length - 1 && keyItem) {
      acc.unshift(keyItem);
    }

    if (item.id !== id) {
      acc.push(item);
    }

    return acc;
  }, []);
}

export function head<T>(array: T[] | undefined) {
  return first(array);
}

export function first<T>(array: T[] | undefined) {
  return Array.isArray(array) ? array[0] : undefined;
}

export function last<T>(array: T[] | undefined) {
  return Array.isArray(array) ? array[array.length - 1] : undefined;
}

export function insertStringAt(str: string, index: number, insert: string) {
  return str.slice(0, index) + insert + str.slice(index);
}

export function isEmptyArray<T>(value: T) {
  return Array.isArray(value) && value.length === 0;
}

export function isEmptyObject<T>(value: unknown): value is T {
  return typeof value === "object" && value !== null && Object.keys(value).length === 0;
}

export function isNil(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}
