import { useModal } from "@lobby/core/src/shared";
import { clsx } from "clsx";

export function MobileMenuButton({ className }: { className?: string }) {
  const { open: openProfile } = useModal("profile");

  return (
    <div
      className={clsx(
        className,
        "flex flex-col gap-1 w-[1.5625rem] *:rounded-1 *:bg-keppel *:dark:bg-java *:h-1",
      )}
      onClick={openProfile}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
