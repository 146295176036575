import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { type ChangeEvent, type ForwardedRef, forwardRef, useState } from "react";

interface IInputSearchProps {
  className?: string;
  label?: string;
  onChange?: (value: string) => void;
}

export const InputSearch = forwardRef(function InputSearch(
  {
    className,
    label,
    onChange,
    ...inputProps
  }: IInputSearchProps & Omit<React.ComponentProps<"input">, "onChange">,
  ref?: ForwardedRef<HTMLInputElement>,
) {
  const { $t } = useTranslate();
  const [text, setText] = useState("");

  function handleClear() {
    handleChange("");
  }

  function handleInput(e: ChangeEvent<HTMLInputElement>) {
    handleChange(e.target.value);
  }

  function handleChange(value: string) {
    const formattedValue = value.toLowerCase();

    setText(formattedValue);
    onChange?.(formattedValue);
  }

  return (
    <div className={className}>
      <label className="flex items-center">
        {label && (
          <span className="mr-2.5 font-bold">
            {label}
            {skipIntl(":")}
          </span>
        )}
        <div className="grow relative">
          <input
            ref={ref}
            type="text"
            placeholder={$t({ defaultMessage: "Search" })}
            {...inputProps}
            value={text}
            onChange={handleInput}
            autoCorrect="off"
            className="peer w-full px-8"
          />
          <button type="button" className="-translate-y-1/2 absolute top-1/2 left-2.5">
            <svg width="1em" height="1em" viewBox="0 0 16 16">
              <path
                d="M10.09 4.02a4.36 4.36 0 1 1-6.18 6.17 4.36 4.36 0 0 1 6.18-6.17Zm2.06 6.82a6.37 6.37 0 1 0-1.41 1.42l3.14 3.14a1 1 0 0 0 1.41-1.42l-3.14-3.14Z"
                fill="#546B81"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={handleClear}
            hidden={!text}
            className="-translate-y-1/2 absolute top-1/2 right-2.5"
          >
            <svg width="1em" height="1em" viewBox="0 0 12 12">
              <path
                d="m7.51 6 4.25-4.24L10.34.34 6.1 4.6 1.86.34.44 1.76 4.7 6 .44 10.24l1.42 1.42L6.1 7.4l4.24 4.25 1.42-1.42L7.5 6Z"
                fill="#546B81"
              />
            </svg>
          </button>
          <div className="invisible absolute left-2.5 font-light text-12 text-radical-red peer-invalid:visible">
            {$t({ defaultMessage: "Required" })}
          </div>
        </div>
      </label>
    </div>
  );
});
